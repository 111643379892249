/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IContactPage, ILocation } from '@standardTypes';

import React from 'react';

import { Layout } from '@components/layout';
import { ContactSection } from '@components/organisms/standard';

interface IndexPageProps {
  pageContext: {
    page: IContactPage & { ACF_CheckboxesLabels: Record<string, string> }; // IPage
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const IndexPage: React.FC<IndexPageProps> = ({ pageContext }) => {
  const { page, translationUrls } = pageContext;
  const { ACF_ContactPageCompanyInformation, ACF_CheckboxesLabels } = page;

  return (
    <Layout {...page} translationUrls={translationUrls} withoutPadding>
      <ContactSection
        acfData={ACF_ContactPageCompanyInformation}
        checkboxLabels={ACF_CheckboxesLabels}
      />
    </Layout>
  );
};

export default IndexPage;
